.prfilePicSection img {
    width: 35px;
    height: 35px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 10px;
}
.profileMenuSectionRow {
    display: flex;
}
.profileMenuSectionRow p{
    margin: 0;
}
.profileMenuSectionRow p {
    margin: 0;
    font-weight: 600;
}
.profileMenuSectionRow small {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
}
@media (min-width: 900px) {
    .css-10j3si5-MuiInputBase-root:hover .MuiInputBase-input {
        /* width: 50ch!important; */
            
        transition: .5s;
    }
    .css-10j3si5-MuiInputBase-root .MuiInputBase-input {
       
        transition: .5s;
    }
}
@media (max-width: 900px) {
    span.menuList {
        display: none;
    }
}

