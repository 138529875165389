
.boxshadowBorder {
    padding: 0px 20px;
    box-shadow: 0px 1px 5px 0px #ccc;
 
}

.contact-us h3 {
    color: #030d4e;
    font-weight: 800;
    font-size: 2rem;
    padding-top: 15px;
}
.contact-us input, .contact-us textarea {
    border: 1px solid #fbfbfb;
    border-radius: 5px;
    padding: 10px 10px;
    box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px !important;
}
.form_parent {
    
    justify-content: flex-start;
    align-items: center;
    padding: 5px 0px;
    width: 100%;
}

.btnStyle {
    padding: 10px 40px;
    border: 0px solid transparent;
    background: #080012;
    color: #ffffff;
    border-radius: 30px;
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 400;
    font-family: 'Poppins';
}
.contactSubHead {
    color: #2e2e2e !important;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
    display: block;
}
.mapSection {
    box-shadow: 0px 1px 5px 0px #ccc;
    border-radius: 2px;
}
.borderBttn {
    border-bottom: 1px solid #d2d2d2;
    padding: 7px 20px;
}
.borderBttn h3 {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
}
.address {
    padding: 10px 20px 12px 20px;
}
.container.contactUsHeightForFotter {
    max-width: 1300px;
    width: 100%;
    height: auto;
    margin: auto;
    padding: 4rem 0 5rem 0;
}
@media(min-width: 901px){
    .form_parent label {
        width: 15%;
    }
    .form_parent input, .form_parent textarea {
        width: 85%;
    }
    .form_parent { 
        display: flex;

    }
}
@media (max-width: 900px){
    .form_parent input, .form_parent textarea {
        width: 100%;
    }
    .form_parent label {
        width: 100%;
    }
    
    label {
        display: block;
    }
    .form_parent {
        display: block;
    }
    .row{
        display: block;
    }
}
.row{
    display: flex;
}