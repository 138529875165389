@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&display=swap');

body{
  font-family: 'Sora', sans-serif;
}
h1, h2, h3, h4, h5, h6{
  font-family: 'Sora', sans-serif;
}
span, p, a, b, strong, input, label, small, em, button, div{
  font-family: 'Sora', sans-serif!important;
}
.fieldCS {
    width: 100%;
}
.InputField {
    margin-bottom: 20px;
    position: relative;
}
.imageWidth {
    width: 100%;
}
.signupcontainer {
    margin: auto;
    display: flex;
    vertical-align: middle;
    align-items: center;
    flex-direction: inherit;
}
.Logincontainer {
    margin: auto;
    display: flex;
    vertical-align: middle;
    align-items: center;
    flex-direction: inherit; 
}
.googleLoginsvG svg{
    margin-right: 15px;
}
.passvisiblePass {
    position: absolute;
    right: 15px;
    bottom: 0px;
}
@media (max-width: 900px) {
    .Logincontainer{
        display: block;
    }
    .signupcontainer{
        display: block;
    }
}