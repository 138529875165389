.searchInput {
    border: 0px solid transparent;
    border-bottom: 1px solid #cccccc;
    width: -webkit-fill-available;
    font-size: 20px;
    font-weight: 300;
    padding: 10px 0px 10px 50px;
}
#customized-dialog-title {
    margin: 20px 20px 0px 0px;
}
:focus{
    outline: 0;
}