.headparent {
    background: white;
    padding: 10px 20px;
}
 
.logoTxt img {
    border-radius: 5px 0px 5px 0px;
    margin-right: 10px;
    vertical-align: middle;
    display: inline-block;
    padding-top: 4.5px;
    height: 47px;
    object-fit: contain;
}
.titleSpan {
    display: inline-block;
    font-size: 25px;
    line-height: 0;
    margin: 0;
    padding-top: 4.5px;
    vertical-align: middle;
    font-weight: 600;
    color: #2791f7;
}
.logoImage {
    width: auto;
    display: inline-block;
}
span.menuList {
    width: auto;
    display: inline-block;
}
.menuList li {
    list-style: none;
    display: inline;
    margin: 0px 10px;
}
span.menuList ul {
    margin: 0;
}
.headparent {
    background: white;
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* box-shadow: 0px 1px 9px 0px rgb(0 0 0 / 14%); */
}
.menuList a {
    color: #333;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 600;
}
.menuListMobile ul {
    margin: 0;
    list-style: none;
    padding: 0;
    background: #fff;
    border-top: 1px solid #eeeeee;
    padding-top: 15px;
}
.menuListMobile {
    background: #000000bd;
    display: block;
    position: fixed;
    width: 100%;
    bottom: 0;
    top: 68px;
    z-index: 99;
}
.menuListMobile a {
    padding: 10px 20px;
    display: block;
    text-decoration: none;
    font-weight: 500;
    border-bottom: 1px solid #eee;
}
@media (min-width: 900px){
    .menuListMobile, .menuBtns {
        display:  none;
    }
    .searchBox{
        display: none;
    }
    .headerButton{
        font-size: 1rem!important;
    }
    .titleSpan{
        font-size: 1.9rem!important;
    }
}
@media (max-width: 900px){
    .headerFixed{
       
        width: 91%!important;
    
     }
     .headerButton{
        font-size: 1rem;
    }
}

input#searchYahoo {
    border: 2px solid #cccccc;
    border-radius: 20px;
    padding: 5px 10px;
    box-shadow: 0px 0px 4px #fff;
    width: 250px;
    font-size: 14px;
    line-height: 1;
}

.widgetDiv ul {
    margin: 0;
    padding: 0;
    text-align: center;
}
.widgetDiv {
    width: 190px;
    background: #fefdfe;
    position: absolute;
    right: 40px;
    box-shadow: 0px 0px 12px 10px #cd5ba917;
    border-radius: 10px;
    top: 70px;
    z-index: 99;
}
.widgetDiv li {
    display: block;
    padding: 10px;
    border-top: 1px solid  #cd5ba917;
}
