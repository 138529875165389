@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&display=swap');

body{
  font-family: 'Sora', sans-serif;
}
h1, h2, h3, h4, h5, h6{
  font-family: 'Sora', sans-serif;
}
span, p, a, b, strong, input, label, small, em, button, div{
  font-family: 'Sora', sans-serif!important;
}
.App{
  overflow-x: hidden;
}
.searchTermsDiv{
  width: 1200px;
  margin: 0 18%;
}