.HomeBodyH1 h1 {
    font-size: 4.39rem;
    text-align: center;
    margin: 0;
    padding: 0;
    font-weight: 900;
}
.HomeBodyH1 h3 {
    text-align: center;
    color: #868686;
    font-weight: 400;
    font-size: 1.7rem;
    margin: 5px;
    font-weight: 300;
    margin-bottom: 40px;
}
.imageAdjustMent {
    width: 100%;
    margin: auto;
    box-shadow: 39px 52px 65px 42px #f5e2ef;
    border-radius: 15px;
}
.textCenter {
    margin:  60px 0px;
}
.patnersListSection {
    text-align: center;
    margin: 50px 20px;
    color: #464646;
}
.logos img {
    height: 56px;
    margin: 0px 20px;
    display: inline-block;
}
.logos {
    text-align: center;
}
.partnersListImage {
    margin-bottom: 30px;
}
.infoslideShow {
    /* max-width: 1300px; */
    width: 100%;
    margin: auto;
}
.info1 {
    display: flex;
    margin: 12rem 0rem;
    vertical-align: middle;
    align-items: center;
    align-self: center;
}
.leftSide b{
    color: #2791f7;
    font-size: 1rem;
}
.leftSide {
    text-align: left!important;
    margin-right: 5rem;
    vertical-align: middle;
    flex: 1;
}
.leftSide h3{
    text-align: left!important;
    color: #000000;
    font-weight: bold;
    font-size: 2rem;
   
}
.leftSide p{
    font-size: 1rem;
   line-height: 1.5;
   color: #8a8787;
}
.footer{
    /* background: #e0f0ff; */
    padding: 20rem 1rem 4rem 1rem;
    background-image: url(./../../public/images/Footer/Vector8.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: bottom;
     /* min-height: 50vh; Adjust this value to match the height of the footer */

}
.adminFooter{
    padding: 2rem 1rem 4rem 1rem;
    background-color: #770a97;
}
.modeButton {
    margin: 0px 6px !important;
    padding: 10px 50px !important;
}
.firstLayer {
    text-align: center;
    max-width: 1300px;
    width: 100%;
    margin: auto;
    padding: 0 0 5rem 0;
    /* border-bottom: 1px solid #b5dcfc; */
}
.firstLayer h2{
    font-size: 2.5rem;
    margin: 3rem 9rem;
    background: -webkit-linear-gradient(45deg, #D15CA6, #8E4BCC 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
}
.secondLayer{
    max-width: 1300px;
    width: 100%;
    margin: auto;
    padding: 2rem 2rem 1rem;
}
.footerArticle {
    margin: 2rem 0;
    color: #fff;
}
.Footerrow {
    display: flex;
    vertical-align: top;
    align-items: baseline;
    color: #fff;
}
.firstRow {
width: 30%;
padding: 1rem
}
.secondRow {
 width: 20%;
 padding: 1rem
}
.thirdRow {
    width: 20%;
    padding: 1rem
}
.fourthRow {
    width: 20%;
    padding: 1rem
}
.fifthRow {
    width: 10%;
    padding: 1rem
}
.Footerrow a, .adminFooter a{
    text-decoration: none;
    color: #ffffff;
}
.Footerrow a:hover, .adminFooter a:hover{
    color: #2791f7;
} 
.socialIconsUL {
    list-style: none
} 
.socialIconsUL li{
    margin-bottom: 1.5rem;
}
.lastLayer{
    max-width: 1300px;
    width: 100%;
    margin: auto;
    color: #fff;
}
.rightSide{
    flex: 1;
}
.rightSide img{
    width: 75%;
}
@media (max-width: 900px) {
    .info1 {
        display: block;
    }
    .rightSide img{
        width: 100%;
    }
    .Footerrow{
        display: block;
    }
    .firstRow, .secondRow, .thirdRow, .fourthRow, .fifthRow{
        width: 100%;
        padding: 0rem;
        text-align: center
    }
    .firstLayer h2 {
        font-size: 2rem;
        margin: 0;
        padding-bottom: 3rem;
    }
    .HomeBodyH1 h1 {
        font-size: 3rem;
        text-align: center;
    }
    .secondLayer {
        max-width: 1300px;
        width: 100%;
        margin: auto;
        padding: 3rem 0rem;
    }
    .firstRow p, .secondRow p, .thirdRow p, .fourthRow p, .fifthRow p {
        font-size: 0.8rem;
        margin: 5px;
        padding: 0;
    }
    .socialIconsUL {
        margin-left: 0;
        padding-left: 0;
        display: flex;
        justify-content: space-evenly;
    }
    .HomeBodyH1 h3{
        font-size: 1.4rem;
    }
    .info1{
        margin: 5rem 1rem;
    }
    .leftSide p {
        font-size: 1.4rem;
        
        
    }
    .leftSide {
         
        margin-right: 0rem;
         
    }
    .floatingBackGround {
        height: 24vh!important;
    }
}
.HomeBodyH1 {
    margin: auto;
    padding: 20px;
    background-image: linear-gradient(181deg, white, #c48ff55c);
}
ul.rowUlPoints {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 0;
    justify-content: center;
}
.rowUlPoints li {
    list-style: none;
    background: #fff;
    padding: 20px;
    margin: 10px;
    /* box-shadow: 0px 0px 4px #ddd; */
    font-size: 16px;
    font-weight: 400;
    border-radius: 6px;
    border-image-source: linear-gradient(91deg, #D15CA6 5.77%, #8E4BCC 91.29%);
    box-shadow: 0px 3px 50px 0px #770A9740;
}
.GradText{
    background: linear-gradient(90deg, #D15CA6 0%, #8E4BCC 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.SecondBody{    
    max-width: 1300px;
    width: 100%;
    margin: 3% auto;
}
.modeButton {
    margin: 20px 10px;
    padding: 5px 30px;
}
.line {
    width: 137px;
    border: 3px solid #8E4BCC;
    margin-bottom: 10px;
}
.sub_title{
    margin:  10px 30px;
}

iframe{
    display: none;
}