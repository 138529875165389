.formInput label {
    font-size: 16px;
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}
.formInput input {
    width: 100%;
    margin-bottom: 10px;
    height: 30px;
    border: 1px solid #8f8f8f;
    padding: 0px 5px;
}