.searchNameLogo img {
    width: 50px;
    /* width: 32px; */
    border-radius: 5px 0px 5px 0px;
    margin-right: 10px;
    vertical-align: middle;
    display: inline-block;
    padding-top: 4.5px;
}
    
    .searchSection {
        max-width: 1000px;
        margin: auto;
        text-align: center;
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        margin: auto;
        height: fit-content;
        text-align: center;
    }
    .heightAd{
        height: 71vh;
        /* position: relative; */
    }
    .searchNameLogo{
        margin-bottom: 1.2rem;
        display: flex;
        align-items: end;
        justify-content: center;
    }
    .searchNameLogo small {
        color: #2791f7;
        font-weight: 600;
        font-size: 40px;
    }
    .searchInputData input{
    width: 100%!important; 
    padding: 0.7rem 2rem!Important;
    border-radius: 50px!important;
    color: #3c4043;
    font-size: 1.3rem!Important;
    border: 1px solid #ccc;
    } 
    .searchInputData{
        width: 100%!important;
        margin-bottom: 5rem;
        
    }
    
    
    
    
    
    
    
    
    .relTileTo {
        color: #70757a;
        font-size: 14px;
        margin-left: 12px;
        margin-top: 30px;
        margin-bottom: 10px;
    }
    .related-tag-line {
        display: inline-block;
        list-style: none;
        padding: 0 5px;
    }
    .relatedtag {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        line-height: 1.2;
        border-radius: 30px;
        overflow-x: hidden;
        background-color: #fff;
        text-align: center;
        display: inline-table;
        margin: 4px;
        -webkit-transition: all .1s ease-in 0s;
        -moz-transition: all .1s ease-in 0s;
        transition: all .1s ease-in 0s;
        cursor: pointer;
        border: thin solid #f9f9f9!important;
        -webkit-box-shadow: 0 1px 2px hsla(0,0%,43.1%,.2784313725490196);
        box-shadow: 0 1px 2px hsla(0,0%,43.1%,.2784313725490196);
    }
    .relatedtag .tag-title {
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        color: #3c4043!important;
        display: block;
        padding: 12px 10px 12px 12px;
        text-decoration: none;
    }
    .tag-title svg {
        vertical-align: middle;
        width: 20px;
        height: 18px;
        margin-left: 15px;
        float: right;
        fill: #7100ff;
    }
    ul.ypaAdUnit li {
        line-height: 1.5;
        color: #3c4043!important;
    }
    
    .ypaAdElement {
        display: table-row;
        padding: 0px;
        margin: 0px;
        width: 100%;
    }
    .ypaAdTextDiv {
        display: flex!important;
        flex-direction: column;
    }
    
    .ypaAdTextDiv {
        padding-bottom: 25px!important;
    }
    .ypaAdTextDiv {
        vertical-align: top;
        display: table-cell;
        padding: 0 0 15px 0;
        width: 100%;
    }
    a:-webkit-any-link {
        /* color: -webkit-link; */
        cursor: pointer;
    }
    
    .ypaAdAnchor {
        font-style: normal;
        text-decoration: none;
    }
    .ypaAdURL {
        color: #000000;
        font-size: 14px;
    }
    .ypaAdDesc {
        color: #3c4043;
        font-size: 14px;
    }
    
    .ypaAdDesc {
        font-family: arial,sans-serif;
    }
    ul.ypaAdUnit li {
        line-height: 1.5;
        color: #3c4043!important;
    }
    
     
    .ypaAdSpacing {
        margin: 0 0 15px 0!important;
    }
    .ypaAdElement {
        padding: 0px;
        display: block;
        width: 100%;
    }
    .ypaAdSS .ypaAdURLInner:before {
        content: "Ad . ";
        color: #000;
        display: inline-block;
        font-size: 16px;
        font-weight: 700;
        margin-left: 0;
        margin-right: 5px;
        white-space: nowrap;
        border: 0 solid #fff;
    }
    .ypaAdLabel {
        text-align: left;
    }
    .ypaAdLabel {
        display: none;
    }
    
    .ypaAdLabel {
        margin: 5px 0 0 0;
    }
    span.ypaAdFavicon {
        display: none;
    }
    
    .ypaAdFavicon {
        position: relative;
        top: 7px;
    }
    .ypaAdFavicon {
        margin: 0 4px 0 0;
    }
    .rightrel {
        margin-top: 0;
    }
    .ypaAdUnit {
        padding: 0px;
        margin: 0px;
        list-style: none;
        width: 100%;
    }